import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Icon, { INameExtended } from 'components/Icon';

import { breakTranslationNewlines } from '../../../utils';

import * as Styled from './InfoCard.styled';

type InfoCardProps = {
  iconName: INameExtended;
  title: string;
  description?: string | string[];
  step?: number;
  last?: boolean;
  type?: 'howItWorks' | 'criteria';
};

const InfoCard = (props: InfoCardProps) => {
  const {
    iconName,
    title,
    description,
    step,
    last,
    type = 'howItWorks',
  } = props;

  const { t } = useTranslation();

  return (
    <Styled.Card
      className={classNames('info-card', {
        'info-card--last': last,
        'info-card--criteria': type === 'criteria',
      })}
    >
      <Icon name={iconName} className="info-card__icon" />

      {type === 'howItWorks' && <div className="info-card__step">{step}</div>}

      <p className="info-card__title">
        {parse(breakTranslationNewlines(t(title)))}
      </p>
      {description && (
        <div className="info-card__description">
          {typeof description === 'string' ? (
            parse(breakTranslationNewlines(t(description)))
          ) : (
            <ul className="info-card__descriptions">
              {description?.map((desc, index) => (
                <li key={index} className="info-card__descriptions__item">
                  <Icon name="circle-check-solid" />{' '}
                  <p>{parse(breakTranslationNewlines(t(desc)))}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Styled.Card>
  );
};

export default InfoCard;
