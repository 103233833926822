import { FC } from 'react';

import { reasons } from './reasons';
import IconCard from '../../components/_molecules/IconCard';

import { StyledWhyCedolautoSection } from './WhyCedolautoSection.styled';
import { useTranslation } from 'react-i18next';

const WhyCedolautoSection: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledWhyCedolautoSection
      title={t('ced-why-title')}
      className="cedolauto-why-cedolauto"
    >
      <div className="cedolauto-why-cedolauto__cards">
        {reasons.map((reason) => (
          <IconCard {...reason} key={reason.title} />
        ))}
      </div>
    </StyledWhyCedolautoSection>
  );
};

export default WhyCedolautoSection;
