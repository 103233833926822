import { INameExtended } from 'components/Icon';

export const reasons: {
  icon: INameExtended;
  title: string;
  description: string;
}[] = [
  {
    icon: 'calendar-range',
    title: 'ced-why-block1-title',
    description: 'ced-why-block1-copy',
  },
  {
    icon: 'badge-check',
    title: 'ced-why-block2-title',
    description: 'ced-why-block2-copy',
  },
  {
    icon: 'coins-solid',
    title: 'ced-why-block3-title',
    description: 'ced-why-block3-copy',
  },
  {
    icon: 'shield-check',
    title: 'ced-why-block4-title',
    description: 'ced-why-block4-copy',
  },
  {
    icon: 'screwdriver-wrench-solid',
    title: 'ced-why-block5-title',
    description: 'ced-why-block5-copy',
  },
  {
    icon: 'car-solid',
    title: 'ced-why-block6-title',
    description: 'ced-why-block6-copy',
  },
];
