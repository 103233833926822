import { FC, ReactNode } from 'react';

import { Heading, Image } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';
import Slider from '../Slider';
import { StyledBorderCard } from './BorderCard.styled';

type IconProps = { src: string; alt: string };
type SliderProps = { min: number; max: number; step: number };

export type BorderCardProps = {
  iconProps: IconProps;
  sliderProps: SliderProps;
  header: string;
  info: string;
  value: string;
  sliderValue: number;
  onSliderValueChange: (value: number) => void;
  formatValue?: (value: number) => string;
  children?: ReactNode;
};

const BorderCard: FC<BorderCardProps> = (props) => {
  const {
    sliderProps,
    iconProps,
    header,
    info,
    children,
    sliderValue,
    formatValue,
    onSliderValueChange,
  } = props;

  const { t } = useTranslation();

  return (
    <StyledBorderCard className="cedolauto-border-card">
      <article className="cedolauto-border-card__left">
        <header className="cedolauto-border-card__left__header">
          <Image {...iconProps} />
          <Heading color="white" level="3">
            {t(header)}
          </Heading>
        </header>
        <p className="cedolauto-border-card__left__info">{t(info)}</p>
      </article>

      <div className="cedolauto-border-card__middle">
        {children}
        <Slider
          value={sliderValue}
          onChange={(e) => onSliderValueChange(+e.target.value)}
          {...sliderProps}
        />
      </div>

      <article className="cedolauto-border-card__right">
        <p className="cedolauto-border-card__right__value">
          {formatValue?.(sliderValue) ?? sliderValue}
        </p>
      </article>
    </StyledBorderCard>
  );
};

export default BorderCard;
