import { InfoModal } from 'components';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

const ConditionInfoModal: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('ced-application-tab1-condition-label')}
      <InfoModal
        iconName="circle-info"
        title={t('ced-application-tab1-condition-info-title.18')}
      >
        <strong>{t('ced-application-tab1-condition-info1-title')}</strong>
        <p>{t('ced-application-tab1-condition-info1-copy')}</p>
        <strong>{t('ced-application-tab1-condition-info2-title')}</strong>
        <p>{t('ced-application-tab1-condition-info2-copy')}</p>
        <strong>{t('ced-application-tab1-condition-info3-title')}</strong>
        <p>{t('ced-application-tab1-condition-info3-copy')}</p>
      </InfoModal>
    </>
  );
};

export default memo(ConditionInfoModal);
