import { theme } from '@faxi/web-component-library';
import { phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledCarModels = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-around;
  gap: ${theme.sizes.SIZE_20};

  position: absolute;
  bottom: 42px;

  ${phablet(css`
    position: relative;
    gap: ${theme.sizes.SIZE_16};
    bottom: 0;
  `)};

  .cedolauto-CarModels {
    ${phablet(css`
      justify-content: space-around;
    `)};

    &__car {
      width: 100%;
      max-width: ${theme.sizes.SIZE_104};
    }
  }
`;

export { StyledCarModels };
