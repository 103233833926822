import styled, { css } from 'styled-components';
import { theme } from '@faxi/web-component-library';
import { flex, laptop, mobile, phablet } from '@faxi/web-css-utilities';

import { LayoutSection } from '../../layouts';

const StyledWhyCedolautoSection = styled(LayoutSection)`
  .cedolauto-why-cedolauto {
    &__cards {
      width: ${theme.sizes.SIZE_1200};
      display: grid;
      margin: auto;
      margin-top: ${theme.sizes.SIZE_72};
      grid-template-columns: repeat(2, 1fr);

      grid-gap: ${theme.sizes.SIZE_40};

      ${laptop(css`
        width: 100%;
      `)}

      ${phablet(css`
        width: 100%;
      `)}

      ${mobile(css`
        ${flex('column')};
        margin-top: ${theme.sizes.SIZE_40};

        > div:nth-child(odd) {
          order: -1;
        }
      `)}
    }
  }
`;

export { StyledWhyCedolautoSection };
