import { FC, ReactNode, useContext } from 'react';
import TanstackClientContext, {
  TanstackClientContextProps,
} from './TanstackClient.context';
import env from '../../env';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '../../config';

type TanstackClientProps = {
  children?: ReactNode;
};

const isProductionEnv = env.PROD;

const TanstackClient: FC<TanstackClientProps> = (props) => {
  const { children } = props;

  return (
    <TanstackClientContext.Provider value={{}}>
      <QueryClientProvider client={queryClient}>
        {children}
        {!isProductionEnv && <ReactQueryDevtools />}
      </QueryClientProvider>
    </TanstackClientContext.Provider>
  );
};

const useTanstackClient = (): TanstackClientContextProps =>
  useContext(TanstackClientContext);

export { TanstackClient, useTanstackClient };
