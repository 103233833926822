import { theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledSplashSection = styled.section`
  ${flex('column')};
  background: url('/webp/hero.webp') no-repeat fixed;
  background-size: cover;
  padding: ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_80} ${theme.sizes.SIZE_200};
  height: 100vh;

  ${phablet(css`
    padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_20};
  `)};

  .cedolauto-splash {
    &__heading {
      margin: auto 0 0;
      color: var(--BACKGROUND_2_1);
      ${fontSize('112px')};

      ${phablet(css`
        ${fontSize('42px')};
      `)};
    }

    &__description {
      ${fontSize(theme.fontSizes.FONT_44, '56px')};
      color: var(--BACKGROUND_2_1);
      margin: ${theme.sizes.SIZE_10} 0 ${theme.sizes.SIZE_40} 0;

      ${phablet(css`
        ${fontSize(theme.fontSizes.FONT_26, '32px')};
        margin-bottom: auto;
        max-width: ${theme.sizes.SIZE_256};
      `)};
    }

    &__apply-now {
      ${phablet(css`
        width: 100%;
      `)};
    }
  }
`;

export { StyledSplashSection };
