import { createRoot } from 'react-dom/client';

import 'modules/i18n';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(<App />);
