/* eslint-disable @typescript-eslint/no-unused-vars */
import { Select, SelectProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { StyledSelectField } from './SelectField.styled';

export type SelectFieldProps = Omit<
  FieldProps<string, (event: string) => void> & {
    className?: string;
    description?: string;
    disabled?: boolean;
  },
  'value'
> &
  SelectProps<string>;

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const {
    className,
    description,
    dirty,
    error,
    touched,
    value,
    options,
    onChange,
    multiple,
    label,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const selectOnChange = useCallback(
    (value: any) => {
      onChange?.(value as string);
    },
    [onChange]
  );

  return (
    <StyledSelectField
      className={classNames('cedolauto-select-field', className)}
    >
      <label className="cedolauto-select-field__label">{label}</label>
      <Select
        {...rest}
        multiple={multiple as any}
        options={options}
        value={value as string}
        closeOptionsOnClear
        deleteInputAriaLabel="Delete input"
        chevronBtnAriaLabel="Show select options"
        onChange={(ev: string | { value: string }) =>
          selectOnChange(multiple ? ev : (ev as { value: string }).value)
        }
        error={showError}
        errorText={String(error)}
      />
    </StyledSelectField>
  );
};

export default SelectField;
