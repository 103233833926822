import { Button, Icon } from '@faxi/web-component-library';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import parse from 'html-react-parser';
import { Slider, ValueCurrencyBox } from 'components';
import storageService from 'services';
import env from 'env';
import { FinanceCalculationFormFields } from 'models';

import { StyledFinanceCalculationForm } from './FinanceCalculationForm.styled';
import classNames from 'classnames';
import { breakTranslationNewlines, formatMoneyValue } from '../../../../utils';
import { useTranslation } from 'react-i18next';

export type FinanceCalculationFormProps = {
  changeStep: Dispatch<SetStateAction<number>>;
};

const FinanceCalculationForm: FC<FinanceCalculationFormProps> = (props) => {
  const { changeStep } = props;

  const { t } = useTranslation();

  const [initialData, setInitialData] =
    useState<FinanceCalculationFormFields>();

  const [term, setTerm] = useState<number>(
    +(
      storageService.getItem(
        env.VITE_FORM_STORAGE_KEY
      ) as FinanceCalculationFormFields
    )?.term || 64
  );

  const handleSubmit = useCallback(
    async (values: FinanceCalculationFormFields) => {
      try {
        Object.keys(values).forEach((key) => {
          storageService.updateItem(
            key,
            values?.[key as keyof FinanceCalculationFormFields]
          );
        });

        storageService.updateItem('term', `${term}`);
        storageService.updateItem('activeStep', '3');

        changeStep((old) => old + 1);
      } catch (e) {
        console.error(e);
      }
    },
    [term]
  );

  useEffect(() => {
    setInitialData(
      storageService.getItem(env.VITE_FORM_STORAGE_KEY) ||
        ({} as FinanceCalculationFormFields)
    );
  }, []);

  return (
    <StyledFinanceCalculationForm
      className={classNames(
        'cedolauto-finance-calculation-form',
        'cedolauto-application__form'
      )}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <div className="cedolauto-application__form__wrapper">
        <div className="cedolauto-application__form__wrapper__fields">
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab1-title')}{' '}
            <span>{formatMoneyValue(Number(initialData?.price))}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-tan-label')}{' '}
            <span>{initialData?.tan}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-taeg-label')}{' '}
            <span>{initialData?.taeg}</span>
          </div>
          <div className="cedolauto-application__form__wrapper__fields__finance-data">
            {t('ced-application-tab2-residual-label')}
            <span>{formatMoneyValue(Number(initialData?.residualValue))}</span>
          </div>
        </div>

        <div className="cedolauto-finance-calculation-form__term">
          <div className="cedolauto-finance-calculation-form__term__title">
            {t('ced-apply-block2-title')}
          </div>
          <p className="cedolauto-finance-calculation-form__term__desc">
            {t('ced-application-tab2-term-copy')}
          </p>
          <div>
            <Slider
              value={term}
              onChange={(e) => {
                setTerm(+e.target.value);
              }}
            />
            <div className="cedolauto-finance-calculation-form__term__value">
              {term} {t('ced-months')}
            </div>
          </div>
        </div>
      </div>
      <ValueCurrencyBox
        title={t('ced-application-tab2-cost')}
        value={formatMoneyValue(0)}
        info={parse(
          breakTranslationNewlines(t('ced-application-tab2-cost-info'))
        )}
      />

      <div className="cedolauto-finance-calculation-form__actions">
        <Button
          variant="ghost"
          className="cedolauto-finance-calculation-form__actions__prev-step"
          onClick={() => {
            changeStep((old) => old - 1);
          }}
          icon={<Icon name="chevron-down" />}
        >
          {t('ced-action-previous-step')}
        </Button>

        <Button
          variant="outline"
          type="submit"
          className="cedolauto-application__form__submit"
        >
          {t('ced-action-continue')}
        </Button>
      </div>
    </StyledFinanceCalculationForm>
  );
};

export default FinanceCalculationForm;
