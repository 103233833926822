const faqs: { title: string; content: string }[] = [
  {
    title: 'ced-faq-question_1',
    content: 'ced-faq-answer_1',
  },
  {
    title: 'ced-faq-question_2',
    content: 'ced-faq-answer_2',
  },
  {
    title: 'ced-faq-question_3',
    content: 'ced-faq-answer_3',
  },
  {
    title: 'ced-faq-question_4',
    content: 'ced-faq-answer_4',
  },
  {
    title: 'ced-faq-question_5',
    content: 'ced-faq-answer_5',
  },
  {
    title: 'ced-faq-question_6',
    content: 'ced-faq-answer_6',
  },
  {
    title: 'ced-faq-question_7',
    content: 'ced-faq-answer_7',
  },
  {
    title: 'ced-faq-question_8',
    content: 'ced-faq-answer_8',
  },
];

export default faqs;
