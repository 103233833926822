import { QueryClient } from '@tanstack/react-query';

const DEFAULT_STALE_TIME = 5 * 60 * 1000; // milliseconds

import { QueryFunction } from '@tanstack/react-query';

import env from 'env';

export const BASE_PATH = env.VITE_APP_MYPRICE_URL;

const encodePath = (path: string) =>
  path
    .split('/')
    .map((e) => (e ? encodeURIComponent(e) : e))
    .join('/');

export const defaultQueryFn: QueryFunction = async ({ queryKey }) => {
  const [queryKeyPath, body] = queryKey as [string, Record<string, string>];

  const path = encodePath(queryKeyPath);

  const basePath = `${BASE_PATH}/${path}`;

  try {
    const res = await fetch(basePath, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      ...(body && { body: JSON.stringify(body) }),
    });

    if (!res.ok) throw res;

    const json = await res.json();
    return json;
  } catch (err) {
    throw Error(err as string);
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: DEFAULT_STALE_TIME,
    },
  },
});

export default queryClient;
