import { FC, Fragment, ReactNode } from 'react';

import classNames from 'classnames';
import { StyledValueCurrencyBox } from './ValueCurrencyBox.styled';

export type ValueCurrencyBoxProps = {
  title: string;
  value?: number | string;
  currency?: string;
  className?: string;
  error?: string;
  info?: ReactNode;
};

const ValueCurrencyBox: FC<ValueCurrencyBoxProps> = (props) => {
  const { title, className, value = 0, error, info } = props;

  return (
    <StyledValueCurrencyBox
      className={classNames('cedolauto-value-currency-box', className)}
    >
      <div className="cedolauto-value-currency-box__container">
        {!error ? (
          <Fragment>
            <p>{title}</p>

            <div>
              <strong>{value}</strong>
            </div>
          </Fragment>
        ) : (
          <p>{error}</p>
        )}
      </div>
      {info && <small>{info}</small>}
    </StyledValueCurrencyBox>
  );
};

export default ValueCurrencyBox;
