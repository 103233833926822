import { theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, size, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledBorderCard = styled.div`
  max-width: ${theme.sizes.SIZE_1200};
  width: 100%;
  margin: auto;

  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  align-items: center;
  gap: ${theme.sizes.SIZE_48};

  background-color: var(--BACKGROUND_1_2);

  border-left: 6px solid var(--PRIMARY_1_1);
  border-radius: 0px 8px 8px 0px;

  padding: ${theme.sizes.SIZE_20};

  .cedolauto-border-card {
    &__left {
      &__info {
        ${fontSize('18px')};

        ${phablet(css`
          ${fontSize('16px')};
        `)}
      }

      &__header {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_16};

        > h3 {
          ${phablet(css`
            ${fontSize('18px')};
          `)}
        }

        > img {
          ${phablet(css`
            ${size('24px')};
          `)}
        }
      }
    }

    &__middle {
      position: relative;
      ${flex('column', 'center', 'center')};
      gap: ${theme.sizes.SIZE_24};
    }

    &__right {
      color: var(--PRIMARY_1_1);
      font-weight: 600;
      padding-left: ${theme.sizes.SIZE_32};

      ${tablet(css`
        padding-left: 0;
      `)}

      ${phablet(css`
        ${flex('row', 'flex-start', 'baseline')};
        gap: ${theme.sizes.SIZE_8};
      `)}

      > p {
        margin: 0;
      }

      &__value {
        min-width: ${theme.sizes.SIZE_216};
        ${fontSize('40px')};

        ${phablet(css`
          ${fontSize('30px')};
        `)};
      }
    }
  }

  ${phablet(css`
    ${flex('column')};
    gap: ${theme.sizes.SIZE_32};

    border-left: none;
    padding: ${theme.sizes.SIZE_32};
  `)}
`;

export { StyledBorderCard };
