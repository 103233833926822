import { FC, InputHTMLAttributes } from 'react';

import { StyledSlider } from './Slider.styled';

export type SliderProps = InputHTMLAttributes<HTMLInputElement>;

const Slider: FC<SliderProps> = (props) => {
  return <StyledSlider {...props} />;
};

export default Slider;
