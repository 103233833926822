import { FC, memo } from 'react';

import { Image } from '@faxi/web-component-library';
import { StyledCarModels } from './CarModels.styled';

export type CarModelsProps = {};

const CarModels: FC<CarModelsProps> = () => (
  <StyledCarModels className="cedolauto-CarModels">
    <Image
      src="svg/toyota-aygo.svg"
      alt="Toyota Aygo"
      className="cedolauto-CarModels__car"
    />
    <Image
      src="svg/toyota-corolla.svg"
      alt="Toyota Corolla"
      className="cedolauto-CarModels__car"
    />
    <Image
      src="svg/toyota-ch-r.svg"
      alt="Toyota CH-R"
      className="cedolauto-CarModels__car"
    />
    <Image
      src="svg/toyota-rav4.svg"
      alt="Toyota RAV4"
      className="cedolauto-CarModels__car"
    />
  </StyledCarModels>
);

export default memo(CarModels);
