import { InfoCardType } from '../../models';

export const INFO_CARDS: InfoCardType[] = [
  {
    iconName: 'laptop',
    title: 'ced-howitworks-block1-title',
    description: 'ced-howitworks-block1-subtitle',
  },
  {
    iconName: 'car-wrench',
    title: 'ced-howitworks-block2-title',
    description: 'ced-howitworks-block2-subtitle',
  },
  {
    iconName: 'coins',
    title: 'ced-howitworks-block3-title',
    description: 'ced-howitworks-block3-subtitle',
  },
  {
    iconName: 'car',
    title: 'ced-howitworks-block4-title',
    description: 'ced-howitworks-block4-subtitle',
  },
];
