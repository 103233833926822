import { theme } from '@faxi/web-component-library';
import { flex, mobile, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { LayoutSection } from 'layouts';

const StyledApplyNowSection = styled(LayoutSection)`
  .cedolauto-layout__heading {
    margin-bottom: ${theme.sizes.SIZE_24};
  }

  ${flex('column')}
  gap: ${theme.sizes.SIZE_24};

  color: var(--BACKGROUND_2_1);

  ${phablet(css`
    padding: ${theme.sizes.SIZE_48} 0;

    .cedolauto-layout__heading {
      padding: 0 ${theme.sizes.SIZE_24};
      margin-bottom: 0;
    }
  `)};

  .cedolauto-apply-now-section {
    &__payment {
      ${flex('column', 'center', 'center')};
      gap: ${theme.sizes.SIZE_4};

      width: 100%;
      margin: auto;
      max-width: ${theme.sizes.SIZE_504};

      > p {
        text-align: center;
        padding: 0 ${theme.sizes.SIZE_16};
      }

      .button__icon--has-text {
        transform: translateY(0);
      }

      ${mobile(css`
        max-width: ${theme.sizes.SIZE_304};

        > button {
          width: 100%;
        }

        > p {
          padding: 0;
        }
      `)}
    }
  }
`;

export { StyledApplyNowSection };
