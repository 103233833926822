import { Button } from '@faxi/web-component-library';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BorderCard, Icon, ValueCurrencyBox } from 'components';

import CARDS_DATA from './data';

import CarModels from '../../components/_molecules/CarModels';

import {
  calculateMonthlyPayment,
  formatMoneyValue,
  scrollIntoViewPerClassname,
} from '../../utils';

import { StyledApplyNowSection } from './ApplyNowSection.styled';

const [defaultCarValue, defaultTerm] = CARDS_DATA.map(
  ({ sliderProps: { min, max } }) => Math.round((min + max) / 2)
);

const ApplyNowSection: FC = () => {
  const [carValue, setCarValue] = useState<number>(defaultCarValue);
  const [term, setTerm] = useState<number>(defaultTerm);

  const { t } = useTranslation();

  return (
    <StyledApplyNowSection
      title={t('ced-applynow-title')}
      variant="dark"
      className="cedolauto-apply-now-section"
    >
      <BorderCard
        onSliderValueChange={setCarValue}
        formatValue={formatMoneyValue}
        sliderValue={carValue}
        {...CARDS_DATA[0]}
      >
        <CarModels />
      </BorderCard>

      <BorderCard
        sliderValue={term}
        onSliderValueChange={setTerm}
        {...CARDS_DATA[1]}
      />

      <article className="cedolauto-apply-now-section__payment">
        <ValueCurrencyBox
          title={t('ced-apply-estimation')}
          value={formatMoneyValue(calculateMonthlyPayment(carValue, term))}
        />

        <p>{t('ced-apply-estimation-subtext')}</p>

        <Button
          icon={<Icon name="arrow-right" />}
          iconPosition="right"
          onClick={() => {
            scrollIntoViewPerClassname('cedolauto-application');
          }}
        >
          {t('ced-action-approval')}
        </Button>
      </article>
    </StyledApplyNowSection>
  );
};

export default ApplyNowSection;
