import _set from 'lodash.set';
import env from 'env';

export type StorageServiceType = {
  ram: Record<string, string | undefined>;
  setItem: (key: string, value: string | Record<string, any>) => void;
  removeItem: (key: string) => void;
  getItem: <T>(key: string) => T;
  updateItem: (key: string, value: string | Record<string, any>) => void;
};

export default {
  ram: {} as Record<string, string | undefined>,
  setItem(key: string, value: Record<string, unknown> | string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
  removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.warn(e);
      this.ram[key] = undefined;
    }
  },
  getItem<T>(key: string): T {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (e) {
      console.warn(e);
      return JSON.parse(this.ram[key] as string);
    }
  },
  updateItem(key: string, value: Record<string, unknown> | string): void {
    try {
      const currentData: Record<string, any> =
        this.getItem(env.VITE_FORM_STORAGE_KEY) || {};

      _set(currentData, key, value);

      this.setItem(env.VITE_FORM_STORAGE_KEY, currentData);
    } catch (e) {
      console.warn(e);
      this.ram[key] = JSON.stringify(value);
    }
  },
} as StorageServiceType;
