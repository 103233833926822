import { InfoModal } from 'components';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

const SetUpInfoModal: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      {t('ced-application-tab1-setup-label')}
      <InfoModal
        iconName="circle-info"
        title={t('ced-application-tab1-setup-info-title')}
      >
        <p>{t('ced-application-tab1-setup-info-copy')}</p>
      </InfoModal>
    </>
  );
};

export default memo(SetUpInfoModal);
