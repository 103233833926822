import { theme } from '@faxi/web-component-library';
import { flex, laptop, mobile, size, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledIconCard = styled.div`
  ${flex('row')};

  color: var(--SECONDARY_1_1);

  .cedolauto-icon-card {
    &__icon {
      ${flex('row', 'center', 'center')};

      ${size(theme.sizes.SIZE_72)};
      background-color: var(--PRIMARY_1_1);
      border-radius: 50%;
      margin-right: ${theme.sizes.SIZE_48};

      svg {
        ${size(theme.sizes.SIZE_32)};
      }

      ${tablet(css`
        ${size(theme.sizes.SIZE_48)};
        margin-right: ${theme.sizes.SIZE_24};

        svg {
          ${size(theme.sizes.SIZE_24)};
        }
      `)}

      ${mobile(css`
        ${size(theme.sizes.SIZE_32)};
        margin-right: ${theme.sizes.SIZE_16};

        svg {
          ${size(theme.sizes.SIZE_16)};
        }
      `)}
    }

    &__content {
      flex: 1;
    }

    &__title {
      font-size: ${theme.fontSizes.FONT_28};
      font-weight: 600;
      margin: 0;
      margin-bottom: ${theme.sizes.SIZE_2};

      ${laptop(css`
        font-size: ${theme.fontSizes.FONT_24};
      `)}

      ${tablet(css`
        font-size: ${theme.fontSizes.FONT_20};
      `)}

      ${mobile(css`
        font-size: ${theme.fontSizes.FONT_18};
      `)}
    }

    &__description {
      font-size: ${theme.fontSizes.FONT_22};
      margin: 0;

      ${laptop(css`
        font-size: ${theme.fontSizes.FONT_20};
      `)}

      ${tablet(css`
        font-size: ${theme.fontSizes.FONT_18};
      `)}

      ${mobile(css`
        font-size: ${theme.fontSizes.FONT_16};
      `)}
    }
  }
`;

export { StyledIconCard };
