import {
  IName,
  Icon,
  IconProps,
  extendPropToFaNameMap,
  propToFaNameMap,
} from '@faxi/web-component-library';
import { FC } from 'react';

export type INameExtended =
  | 'arrow-right'
  | 'badge-check'
  | 'calendar-range'
  | 'car-solid'
  | 'car-wrench'
  | 'circle-check-solid'
  | 'circle-info'
  | 'coins-solid'
  | 'coins'
  | 'file-invoice'
  | 'laptop'
  | 'screwdriver-wrench-solid'
  | 'shield-check'
  | 'user-tie-light'
  | 'user-tie'
  | IName;

const customMapNameToFa: Record<INameExtended, string[]> = {
  'arrow-right': ['fa-light', 'fa-arrow-right'],
  'badge-check': ['fa-solid', 'fa-badge-check'],
  'calendar-range': ['fa-solid', 'fa-calendar-range'],
  'car-solid': ['fa-solid', 'fa-car'],
  'car-wrench': ['fa-light ', 'fa-car-wrench'],
  'circle-check-solid': ['fa-duotone', 'fa-circle-check'],
  'coins-solid': ['fa-solid', 'fa-coins'],
  'coins': ['fa-light', 'fa-coins'],
  'file-invoice': ['fa-light', 'fa-file-invoice'],
  'laptop': ['fa-light', 'fa-laptop'],
  'screwdriver-wrench-solid': ['fa-solid', 'fa-screwdriver-wrench'],
  'shield-check': ['fa-solid', 'fa-shield-check'],
  'user-tie-light': ['fa-light', 'fa-user-tie'],
  'user-tie': ['fa-solid', 'fa-user-tie'],
  ...propToFaNameMap,
};

extendPropToFaNameMap(customMapNameToFa);

export default Icon as FC<IconProps<INameExtended>>;
