import { theme } from '@faxi/web-component-library';
import { flex, mobile, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { LayoutSection } from '../../layouts';

const StyledHowItWorksSection = styled(LayoutSection)`
  .cedolauto-how-it-works {
    &__cards {
      ${flex('row', 'center')};
      gap: ${theme.sizes.SIZE_32};

      ${tablet(css`
        height: fit-content;
        margin-top: ${theme.sizes.SIZE_40};
      `)}

      ${mobile(css`
        ${flex('column', 'center', 'center')};
        height: auto;
        gap: 0;
      `)}
    }
  }
`;

export { StyledHowItWorksSection };
