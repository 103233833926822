export default [
  {
    iconProps: {
      src: 'svg/car-value.svg',
      alt: 'Car Value icon',
    },
    sliderProps: {
      step: 500,
      min: 1000,
      max: 20000,
    },
    header: 'ced-apply-block1-title',
    info: 'ced-apply-block1-copy',
    value: '6.000,00',
    unit: 'ced-currency',
  },
  {
    iconProps: {
      src: 'svg/term.svg',
      alt: 'Term icon',
    },
    sliderProps: {
      step: 1,
      min: 1,
      max: 60,
    },
    header: 'ced-apply-block2-title',
    info: 'ced-apply-block2-copy',
    value: '24',
  },
] as const;
