import { theme } from '@faxi/web-component-library';
import { flex, laptop, mobile } from '@faxi/web-css-utilities';
import { LayoutSection } from 'layouts';
import styled, { css } from 'styled-components';

const StyledCriteriaSection = styled(LayoutSection)`
  .cedolauto-criteria-section {
    &__cards {
      ${flex('row', 'center')};
      gap: ${theme.sizes.SIZE_40};

      ${laptop(css`
        gap: ${theme.sizes.SIZE_12};
      `)}

      ${mobile(css`
        ${flex('column')};
        gap: ${theme.sizes.SIZE_24};
      `)}
    }
  }
`;

export { StyledCriteriaSection };
