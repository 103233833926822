import { theme } from '@faxi/web-component-library';
import { flex, fontSize, tablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledValueCurrencyBox = styled.div`
  ${flex('column')};
  gap: ${theme.sizes.SIZE_20};

  .cedolauto-value-currency-box {
    &__container {
      border-radius: ${theme.sizes.SIZE_8};
      border: ${theme.sizes.SIZE_4} solid var(--PRIMARY_1_1);
      padding: ${theme.sizes.SIZE_24};

      > p {
        ${fontSize('28px')};
        color: var(--BACKGROUND_2_1);
        margin: 0;
        text-align: center;

        ${tablet(css`
          ${fontSize('18px')};
        `)}
      }

      > div {
        ${flex('column', 'center', 'center')};
        color: var(--PRIMARY_1_1);

        > p {
          margin: 0;

          ${fontSize('25px')};

          ${tablet(css`
            ${fontSize('18px')};
          `)}
        }

        > strong {
          ${fontSize('50px')};

          ${tablet(css`
            ${fontSize('30px')};
          `)}
        }
      }
    }
  }

  small {
    color: var(--BACKGROUND_2_1);
  }
`;

export { StyledValueCurrencyBox };
