import { FC } from 'react';
import { Button } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { scrollIntoViewPerClassname } from 'utils';

import { StyledSplashSection } from './SplashSection.styled';

const SplashSection: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledSplashSection className="cedolauto-splash">
      <h1 className="cedolauto-splash__heading">CEDOLAUTO</h1>

      <div className="cedolauto-splash__description">
        {t('ced-hero-subtitle')}
      </div>

      <Button
        className="cedolauto-splash__apply-now"
        onClick={() => {
          scrollIntoViewPerClassname('cedolauto-apply-now-section');
        }}
      >
        {t('ced-applynow-title')}
      </Button>
    </StyledSplashSection>
  );
};

export default SplashSection;
