import {
  UtilitiesProvider,
  GlobalStyle as WCLGlobalStyle,
} from '@faxi/web-component-library';
import { Fragment } from 'react';

import { TanstackClient } from './providers';

import {
  ApplicationSection,
  ApplyNowSection,
  SplashSection,
  CriteriaSection,
  WhyCedolautoSection,
  FaqSection,
  HowItWorksSection,
} from 'pages';

import { GlobalStyle } from 'globalStyles';
import { Header } from './components';

const App = () => {
  return (
    <Fragment>
      <WCLGlobalStyle />
      <GlobalStyle />

      <TanstackClient>
        <UtilitiesProvider>
          <Header />
          <main>
            <SplashSection />

            <HowItWorksSection />

            <ApplyNowSection />

            <WhyCedolautoSection />

            <CriteriaSection />

            <FaqSection />

            <ApplicationSection />
          </main>
        </UtilitiesProvider>
      </TanstackClient>
    </Fragment>
  );
};

export default App;
