import { FC } from 'react';
import parse from 'html-react-parser';
import Icon, { INameExtended } from 'components/Icon';

import { StyledIconCard } from './IconCard.styled';
import { useTranslation } from 'react-i18next';
import { breakTranslationNewlines } from '../../../utils';

export type IconCardProps = {
  icon: INameExtended;
  title: string;
  description: string;
};

const IconCard: FC<IconCardProps> = (props: IconCardProps) => {
  const { icon, title, description } = props;

  const { t } = useTranslation();

  return (
    <StyledIconCard className="cedolauto-icon-card">
      <div className="cedolauto-icon-card__icon">
        <Icon name={icon} />
      </div>
      <div className="cedolauto-icon-card__content">
        <p className="cedolauto-icon-card__title">{t(title)}</p>
        <p className="cedolauto-icon-card__description">
          {parse(breakTranslationNewlines(t(description)))}
        </p>
      </div>
    </StyledIconCard>
  );
};

export default IconCard;
