import { InfoCardType } from 'models';

export const CRITERIA_CARDS: InfoCardType[] = [
  {
    iconName: 'user-tie-light',
    title: 'ced-criteria-block1-title',
    description: [
      'ced-criteria-block1-check1',
      'ced-criteria-block1-check2',
      'ced-criteria-block1-check3',
    ],
  },
  {
    iconName: 'car',
    title: 'ced-criteria-block2-title',
    description: [
      'ced-criteria-block2-check1',
      'ced-criteria-block2-check2',
      'ced-criteria-block2-check3',
    ],
  },
  {
    iconName: 'file-invoice',
    title: 'ced-criteria-block3-title',
    description: [
      'ced-criteria-block3-check1',
      'ced-criteria-block3-check2',
      'ced-criteria-block1-check3',
    ],
  },
];
