import { theme } from '@faxi/web-component-library';
import { flex, fontSize, mobile } from '@faxi/web-css-utilities';
import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
  }

  html, body {
    margin: 0;
    text-size-adjust: none;
    scroll-behavior: smooth;
  }

  html, body {
    height: 100%;
  }

  :root {
    --ACCENT_1_1: #03875b !important;
    --PRIMARY_1_1: #00FAA8 !important;
    --SECONDARY_1_1: #2E3246 !important;

    --BACKGROUND_1_1: #2E3246 !important;
    --BACKGROUND_1_2: #3C415A !important;
    --BACKGROUND_2_1: #FFFFFF !important;

    --SHADE_1_1: #2E32460F !important;
    --SHADE_1_2: #E3E8E9 !important;

    --wcl-input-icon-color: #2E3246;
  }

  main {
    ${flex('column', 'stretch', 'stretch')};
  }

  /* overide stepper styles */
  .kinto-stepper {
    &__index {
      color: var(--SECONDARY_1_1) !important;
    }

    &__step {
      &--active {
        &::before {
          background: radial-gradient(
            circle,
            var(--PRIMARY_1_1) 0,
            var(--PRIMARY_1_1) 44%,
            var(--ACCENT_1_1) 45%
          ) !important;
        }

        &::after{
          background-color: var(--SHADE_1_2) !important;
        }
      }

      &--completed {
        &::after {
          background-color: rgb(46, 122, 85) !important;
        }
        
        &::before {
          background-color: var(--PRIMARY_1_1) !important;
        }

        svg {
          color: var(--PRIMARY_1_1) !important;
          margin-right: ${theme.sizes.SIZE_4};
        }

        span {
          color: var(--PRIMARY_1_1) !important;
        }

        .kinto-stepper__index {
          color: var(--BACKGROUND_1_1) !important;
        }
      }
    }
  }

  /* overide language selector styles */
  .cedolauto-language-selector {
    input {
      color: var(--BACKGROUND_1_1) !important;
    }

    .wcl-select__option {
      color: var(--BACKGROUND_1_2) !important;
      background-color: var(--SHADE_1_2) !important;

      &--selected {
        background-color: var(--BACKGROUND_1_2) !important;
        color: var(--SHADE_1_2) !important;
    }
    }
  }

  .position-relative {
    position: relative;
  }

  .button--outline{
    background: transparent !important;
    opacity: 0.8;

    &:hover{
      opacity: 1;
    }
  }

  html body .wcl-overlay .wcl-modal {
    background-color: white;
    max-width: ${theme.sizes.SIZE_1200};

    &__header {
      margin-bottom: ${theme.sizes.SIZE_24};
    }

    &__content {
      color: black;
      ${fontSize('16px')};

      > *:first-child {
        margin-top: 0;
      }
    }

    &__actions {
      ${flex('row', 'flex-end', 'center')};
      margin-top: ${theme.sizes.SIZE_24};

      button {
        color: black;
        font-weight: 400;
        min-width: ${theme.sizes.SIZE_176};

        &::after {
          border-color: black;
        }
      }
    }

    &__close-btn {
      display: none;
    }
  }

  html body .wcl-overlay {
    background-color: transparent;
  }

  html body .wcl-overlay .wcl-overlay__content {
    background-color: rgba(0, 0, 0, 0.85);
    padding: 0 ${theme.sizes.SIZE_48};

    ${mobile(css`
      padding: 0 ${theme.sizes.SIZE_16};
    `)}
  }
`;
