import { theme } from '@faxi/web-component-library';
import { fontSize, phablet, position, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

const StyledLayoutSection = styled.section`
  position: relative;
  padding: ${theme.sizes.SIZE_120} ${theme.sizes.SIZE_88} ${theme.sizes.SIZE_88};

  ${phablet(css`
    padding: ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_24};
  `)};

  &:before {
    ${position('absolute', `top ${theme.sizes.SIZE_64} left 0`)};
    ${size(theme.sizes.SIZE_176, theme.sizes.SIZE_24)};

    display: block;
    content: '';
    background-color: var(--PRIMARY_1_1);

    ${phablet(css`
      ${size(theme.sizes.SIZE_80, theme.sizes.SIZE_12)};

      top: ${theme.sizes.SIZE_24};
    `)};
  }

  .cedolauto-layout {
    &__heading {
      ${fontSize('40px')};

      font-weight: 400;
      margin-bottom: ${theme.sizes.SIZE_56};

      ${phablet(css`
        ${fontSize('22px')};
        margin-bottom: ${theme.sizes.SIZE_24};
      `)};
    }
  }

  &.cedolauto-layout {
    &--dark {
      background-color: var(--SECONDARY_1_1);

      .cedolauto-layout__heading {
        color: var(--BACKGROUND_2_1);
      }
    }

    &--full-size {
      ${phablet(css`
        padding: ${theme.sizes.SIZE_48} 0;

        .cedolauto-layout__heading {
          padding: 0 ${theme.sizes.SIZE_24};
        }
      `)};
    }
  }
`;

export { StyledLayoutSection };
