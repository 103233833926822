import styled, { css } from 'styled-components';
import { LayoutSection } from '../../layouts';
import { flex, fontSize, phablet } from '@faxi/web-css-utilities';
import { theme } from '@faxi/web-component-library';

const StyledFaqSection = styled(LayoutSection)`
  .expander {
    max-width: 840px;

    &__trigger {
      width: 100%;

      &__title {
        ${fontSize(theme.fontSizes.FONT_28, '32px')};
        ${flex('row-reverse', 'flex-end', 'center')};

        color: var(--SECONDARY_1_1);

        ${phablet(css`
          max-width: unset;
          ${fontSize(theme.fontSizes.FONT_22, '25px')};
        `)};

        .expander__icon {
          margin-right: ${theme.sizes.SIZE_12};

          background-color: var(--PRIMARY_1_1);
          border-radius: 50%;
          font-size: 16px;
          padding: 8px;

          color: #2e3246;
        }
      }
    }

    &__container {
      ${fontSize(theme.fontSizes.FONT_22, '25px')};
      margin-left: ${theme.sizes.SIZE_56};
      margin-top: 28px;
      margin-bottom: ${theme.sizes.SIZE_46};

      ${phablet(css`
        ${fontSize(theme.fontSizes.FONT_18, '20px')};

        margin-top: 14px
        margin-bottom: ${theme.sizes.SIZE_32};

      `)};
    }
  }
`;

export { StyledFaqSection };
