import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { INFO_CARDS } from './cards';
import InfoCard from '../../components/_molecules/InfoCard';

import { StyledHowItWorksSection } from './HowItWorksSection.styled';

const HowItWorksSection: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledHowItWorksSection
      title={t('ced-howitworks-title')}
      className="cedolauto-how-it-works"
    >
      <div className="cedolauto-how-it-works__cards">
        {INFO_CARDS.map((card, index) => (
          <InfoCard
            {...card}
            key={card.title}
            step={++index}
            last={index === INFO_CARDS.length}
          />
        ))}
      </div>
    </StyledHowItWorksSection>
  );
};

export default HowItWorksSection;
