import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const thumbStyles = `
  ${size('28px')};
  background: var(--BACKGROUND_2_1);
  border: 1px solid var(--SHADE_1_2);
  border-radius: 50%;
  cursor: pointer;
`;

const StyledSlider = styled.input.attrs({ type: 'range' })`
  ${size('100%', '4px')};

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  border-radius: 4px;
  background: ${({ value = 0, max = 100, min = 0 }) => {
    const range = +max - +min;
    const valueRelativeToRange = +value - +min;
    const percentage = (valueRelativeToRange / range) * 100;

    return `linear-gradient(to right, var(--PRIMARY_1_1) 0%, var(--PRIMARY_1_1) ${percentage}%, var(--SHADE_1_2) ${percentage}%, var(--SHADE_1_2) 100%);`;
  }};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyles}
  }

  &::-moz-range-thumb {
    -moz-appearance: none;
    ${thumbStyles}
  }
`;

export { StyledSlider };
