import { FC, useMemo, useState } from 'react';
import { Stepper } from '@faxi/web-component-library';
import ValueOfYourCarForm from './components/ValueOfYourCarForm';
import FinanceCalculationForm from './components/FinanceCalculationForm';
import FindDealerForm from './components/FindDealerForm';
import storageService from 'services';
import env from 'env';
import { StorageApplicationFormType } from 'models';

import { StyledApplicationSection } from './ApplicationSection.styled';
import { useTranslation } from 'react-i18next';

const ApplicationSection: FC = () => {
  const [currentStep, setCurrentStep] = useState(
    +(
      storageService.getItem(
        env.VITE_FORM_STORAGE_KEY
      ) as StorageApplicationFormType
    )?.activeStep || 1
  );

  const { t } = useTranslation();

  const steps = useMemo(
    () => [
      { name: t('ced-application-tab1-title') },
      { name: t('ced-application-tab2-title') },
      { name: t('ced-application-tab3-title') },
    ],
    [t]
  );

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <ValueOfYourCarForm changeStep={setCurrentStep} />;
      case 2:
        return <FinanceCalculationForm changeStep={setCurrentStep} />;
      case 3:
        return <FindDealerForm changeStep={setCurrentStep} />;
    }
  }, [currentStep]);

  return (
    <StyledApplicationSection
      title={t('ced-application-title')}
      variant="dark"
      className="cedolauto-application"
    >
      <Stepper
        className="template__header__progress"
        steps={steps}
        currentStep={currentStep}
      />
      {renderStep}
    </StyledApplicationSection>
  );
};

export default ApplicationSection;
