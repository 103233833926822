import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSelectField = styled.div`
  ${flex('column', 'flex-start', 'flex-start')};
  gap: ${theme.sizes.SIZE_4};

  .cedolauto-select-field {
    &__label {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize('12px')};

      gap: ${theme.sizes.SIZE_8};
      color: white;
    }
  }

  .wcl-select {
    width: 100%;
  }

  .wcl-select__option {
    background-color: var(--BACKGROUND_1_2) !important;
    &:hover {
      color: var(--PRIMARY_1_1) !important;
    }
  }
  .input__label {
    color: var(--BACKGROUND_2_1) !important;
    opacity: 1 !important;
  }

  .wcl-select__option--selected {
    color: var(--ACCENT_1_1) !important;
  }

  .input__container__placeholder {
    color: var(--ACCENT_1_1) !important;
  }

  .input__container__input {
    color: var(--SECONDARY_1_1) !important;
  }

  .input__container__placeholder--disabled {
    color: var(--SHADE_1_1) !important;
  }

  .wcl-dropdown:has(.wcl-dropdown__trigger--disabled) {
    cursor: not-allowed;
  }

  svg {
    color: var(--SECONDARY_1_1) !important;
  }

  .wcl-dropdown__content {
    border-color: var(--ACCENT_1_1) !important;
  }
`;

export { StyledSelectField };
