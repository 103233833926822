import {
  Button,
  IconProps,
  Modal,
  ModalProps,
} from '@faxi/web-component-library';
import { FC, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { INameExtended } from '../../Icon';
import { StyledInfoModal } from './InfoModal.styled';

export type InfoModalProps = {
  iconName: INameExtended;
  closeButtonText?: string;
  children?: ReactNode;
  iconProps?: Omit<IconProps, 'name'>;
  onClose?: () => void;
  onOpen?: () => void;
} & ModalProps;

const InfoModal: FC<InfoModalProps> = ({
  iconName,
  children,
  position = 'center',
  closeButtonText,
  iconProps,
  onClose,
  onOpen,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const finalButtonText = closeButtonText || t('ced-action-close');

  const handleClose = useCallback(() => {
    onClose?.();
    setIsOpen(false);
  }, [onClose, setIsOpen]);

  const handleOpen = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, [onOpen, setIsOpen]);

  return (
    <StyledInfoModal className="cedolauto-InfoModal">
      <Icon onClick={handleOpen} name={iconName} {...iconProps} />

      {isOpen && (
        <Modal position={position} onClose={handleClose} {...rest}>
          <section className="wcl-modal__content">{children}</section>

          <footer className="wcl-modal__actions">
            <Button onClick={handleClose} variant="outline">
              {finalButtonText}
            </Button>
          </footer>
        </Modal>
      )}
    </StyledInfoModal>
  );
};

export default InfoModal;
