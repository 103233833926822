import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledInputField = styled.div`
  .input__container__placeholder {
    color: var(--ACCENT_1_1) !important;
  }

  .input__container__input--disabled {
    background-color: var(--SHADE_1_4) !important;
    cursor: not-allowed;

    .input__label {
      color: red !important;
    }
  }

  .input__container__placeholder--disabled {
    color: var(--SHADE_1_1) !important;
  }

  .input__container__input {
    color: var(--SECONDARY_1_1) !important;
    border: none !important;
  }

  .input__label {
    color: var(--SHADE_1_2) !important;
  }

  .kinto-input__helper-text {
    font-size: ${theme.fontSizes.FONT_14};
    margin-top: ${theme.sizes.SIZE_8};
    color: var(--BACKGROUND_2_1);
  }
`;

export { StyledInputField };
