const isValidNumber = (value: number) =>
  typeof value === 'number' && isFinite(value);

const isValidPositiveNumber = (value: number) =>
  isValidNumber(value) && value > 0;

/**
 * Calculate the monthly lease payment based on the car value, term, and interest rate.
 *
 * @param carValue The principal amount (the car value) in euro.
 * @param term The term of the lease in months.
 * @param interestRate The annual interest rate in percent (default is 7.5).
 * @returns The monthly lease payment amount in euro.
 *
 * See more: https://www.calculatorsoup.com/calculators/financial/simple-interest-calculator.php
 */
export function calculateMonthlyPayment(
  carValue: number,
  term: number,
  interestRate = 7.5
): number {
  if (![carValue, term, interestRate].every(isValidPositiveNumber)) return NaN;

  const r = +interestRate / 100;
  const years = +term / 12;

  const totalInterest = carValue * r * years;

  return parseFloat(totalInterest.toFixed(2));
}
