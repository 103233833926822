import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import { Form } from '@faxi/web-form';
import styled from 'styled-components';

const StyledFinanceCalculationForm = styled(Form)`
  .cedolauto-finance-calculation-form {
    &__term {
      color: var(--BACKGROUND_2_1);
      font-weight: 500;
      flex: 1;

      &__title {
        font-size: ${theme.fontSizes.FONT_28};
        margin-bottom: ${theme.sizes.SIZE_8};
      }

      &__desc {
        font-weight: ${theme.fontSizes.FONT_20};
        margin-top: 0;
        margin-bottom: ${theme.sizes.SIZE_10};
      }

      &__value {
        font-size: ${theme.fontSizes.FONT_30};
        color: var(--PRIMARY_1_1);
        text-align: end;
        margin-top: ${theme.sizes.SIZE_12};
      }
    }

    &__actions {
      ${flex()};

      &__prev-step {
        svg {
          transform: rotate(90deg);
          margin-bottom: ${theme.sizes.SIZE_4};
        }
      }
    }
  }
`;

export { StyledFinanceCalculationForm };
