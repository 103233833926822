import { WheelEvent, FC, useCallback } from 'react';
import { Input, InputProps } from '@faxi/web-component-library';
import { FieldProps, useFieldError } from '@faxi/web-form';

import { StyledInputField } from './InputField.styled';

const preventWheelScrolling = (event: WheelEvent<HTMLInputElement>) => {
  const target = event.target as HTMLElement;

  target.blur();
  event.stopPropagation();

  setTimeout(() => {
    target.focus();
  }, 0);
};

type ValueOnChange = (event: string) => void;

export type InputFieldProps = FieldProps<string, ValueOnChange> &
  InputProps & {
    helperText?: string;
    convertFunction?: (value: string) => string;
  };

const InputField: FC<InputFieldProps> = (
  props: InputFieldProps
): JSX.Element => {
  const {
    type,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dirty,
    error,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    touched,
    required,
    helperText,
    onChange,
    convertFunction,
    ...rest
  } = props;

  const showError = useFieldError(props);

  const handleOnChange = useCallback(
    (value: string) => {
      const convertedValue = convertFunction?.(value) ?? value;
      onChange?.(convertedValue);
    },
    [convertFunction, onChange]
  );

  return (
    <StyledInputField>
      <Input
        type={type}
        error={showError}
        errorText={String(error)}
        onChange={handleOnChange}
        aria-invalid={showError}
        required={required}
        {...(required && {
          requiredLabel: 'Required',
        })}
        {...(type === 'number' && {
          onWheel: preventWheelScrolling,
        })}
        {...rest}
      />
      {helperText && <p className="kinto-input__helper-text">{helperText}</p>}
    </StyledInputField>
  );
};

export default InputField;
