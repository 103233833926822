import { theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';
import { LayoutSection } from '../../layouts';

const StyledApplicationSection = styled(LayoutSection)`
  .cedolauto-application {
    &__form {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_72};

      ${phablet(css`
        padding: 0 ${theme.sizes.SIZE_20};
        gap: ${theme.sizes.SIZE_24};
      `)};

      &__wrapper {
        ${flex('row', 'space-between', 'center')};
        gap: ${theme.sizes.SIZE_152};

        ${phablet(css`
          flex-direction: column;
          padding: 0;
          gap: ${theme.sizes.SIZE_48};
        `)};

        &__fields {
          ${flex('column')};
          max-width: 40%;
          width: 100%;
          gap: ${theme.sizes.SIZE_32};
          margin-bottom: auto;

          ${phablet(css`
            max-width: unset;
          `)};

          &__finance-data {
            ${flex('row', 'space-between', 'center')};
            ${fontSize(theme.fontSizes.FONT_20)};
            font-weight: 600;
            color: var(--BACKGROUND_2_1);

            > span {
              color: var(--PRIMARY_1_1);
            }
          }
        }
      }

      &__submit {
        margin: 0 0 0 auto;

        ${phablet(css`
          margin-right: 0;
        `)};
      }
    }

    &__value-currency-box {
      flex: 1;
    }
  }

  .kinto-stepper__index {
    background-color: transparent !important;
  }
`;

export { StyledApplicationSection };
