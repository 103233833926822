import { FC } from 'react';
import { Image } from '@faxi/web-component-library';

import { Header } from './Header.styled';
import { LanguageSelector } from '../..';

const SplashSection: FC = () => {
  return (
    <Header className="cedolauto-header">
      <Image src="svg/tfs_logo.svg" alt="" className="cedolauto-header__logo" />

      <LanguageSelector />
    </Header>
  );
};

export default SplashSection;
