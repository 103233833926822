import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import InfoCard from 'components/_molecules/InfoCard';
import { CRITERIA_CARDS } from './criteriaCards';

import { StyledCriteriaSection } from './CriteriaSection.styled';

const CriteriaSection: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCriteriaSection
      title={t('ced-criteria-title')}
      className="cedolauto-criteria-section"
    >
      <div className="cedolauto-criteria-section__cards">
        {CRITERIA_CARDS.map((card, index) => (
          <InfoCard type="criteria" {...card} key={card.title} step={++index} />
        ))}
      </div>
    </StyledCriteriaSection>
  );
};

export default CriteriaSection;
