import { FC, ReactNode } from 'react';

import { Heading } from '@faxi/web-component-library';
import classNames from 'classnames';
import { StyledLayoutSection } from './LayoutSection.styled';

export type LayoutSectionProps = {
  title: string;
  children?: ReactNode;
  variant?: 'light' | 'dark';
  className?: string;
};

const LayoutSection: FC<LayoutSectionProps> = (props: LayoutSectionProps) => {
  const { title, variant = 'light', className, children } = props;

  return (
    <StyledLayoutSection
      className={classNames(
        'cedolauto-layout',
        `cedolauto-layout--${variant}`,
        className
      )}
    >
      <Heading level="2" className="cedolauto-layout__heading">
        {title}
      </Heading>

      {children}
    </StyledLayoutSection>
  );
};

export default LayoutSection;
