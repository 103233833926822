import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormField } from '@faxi/web-form';
import { Button, Icon } from '@faxi/web-component-library';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { InputField } from 'components';
import storageService from 'services';
import env from 'env';
import { FindDealerFormFields } from 'models';

import classNames from 'classnames';
import { breakTranslationNewlines } from '../../../../utils';

import { StyledFindDealerForm } from './FindDealerForm.styled';

export type FindDealerFormProps = {
  changeStep: Dispatch<SetStateAction<number>>;
};

const FindDealerForm: FC<FindDealerFormProps> = (props) => {
  const { changeStep } = props;

  const { t } = useTranslation();

  const [initialData, setInitialData] = useState<FindDealerFormFields | {}>();

  const handleSubmit = useCallback(async (values: FindDealerFormFields) => {
    try {
      Object.keys(values).forEach((key) => {
        storageService.updateItem(
          key,
          values?.[key as keyof FindDealerFormFields]
        );
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    setInitialData(storageService.getItem(env.VITE_FORM_STORAGE_KEY) || {});
  }, []);

  return (
    <StyledFindDealerForm
      className={classNames(
        'cedolauto-find-dealer-form',
        'cedolauto-application__form'
      )}
      onSubmit={handleSubmit}
      initialData={initialData}
    >
      <div className="cedolauto-application__form__wrapper">
        <div className="cedolauto-application__form__wrapper__fields">
          <FormField
            component={InputField}
            name="fullName"
            label={t('ced-application-tab3-name')}
          />
          <FormField
            component={InputField}
            name="phoneNumber"
            label={t('ced-application-tab3-phone')}
          />
          <FormField
            component={InputField}
            name="email"
            label={t('ced-application-tab3-email')}
          />

          <div className="cedolauto-find-dealer-form__input-">
            <FormField
              component={InputField}
              name="zipCode"
              label={t('ced-application-tab3-zip')}
              helperText={t('ced-application-tab3-zip-subtext')}
            />
          </div>
        </div>

        <div className="cedolauto-find-dealer-form__desc">
          <p className="cedolauto-find-dealer-form__desc__title">
            {parse(
              breakTranslationNewlines(t('ced-application-tab3-info-title'))
            )}
          </p>
          <p className="cedolauto-find-dealer-form__desc__content">
            {parse(
              breakTranslationNewlines(t('ced-application-tab3-info-copy'))
            )}
          </p>
        </div>
      </div>

      <div className="cedolauto-find-dealer-form__actions">
        <Button
          variant="ghost"
          className="cedolauto-find-dealer-form__actions__prev-step"
          onClick={() => {
            changeStep((old) => old - 1);
          }}
          icon={<Icon name="chevron-down" />}
        >
          {t('ced-action-previous-step')}
        </Button>

        <Button className="cedolauto-application__form__submit" type="submit">
          {t('ced-action-submit')}
        </Button>
      </div>
    </StyledFindDealerForm>
  );
};

export default FindDealerForm;
