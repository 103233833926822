import { FC } from 'react';
import { Expander } from '@faxi/web-component-library';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../components';
import faqs from './faqs';

import { StyledFaqSection } from './FaqSection.styled';

const FaqSection: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFaqSection className="cedolauto-faq" title="FAQ">
      {faqs.map(({ title, content }) => (
        <Expander
          title={t(title)}
          body={t(content)}
          key={title}
          icon={<Icon name="chevron-down" />}
        />
      ))}
    </StyledFaqSection>
  );
};

export default FaqSection;
