import styled from 'styled-components';

const StyledInfoModal = styled.div`
  i {
    cursor: pointer;
    svg {
      color: white !important;
    }
  }
`;

export { StyledInfoModal };
